.ticketsTable_component {
  margin-top: -1rem;
}

.ticketsTable {
  height: 72vh;
  overflow: auto;
  border-radius: 0.25rem;
  border: 0.5px solid var(--Base-Blue-grey-5, #c0c3ce);
  background: var(--0, #fff);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ticketsTable::-webkit-scrollbar {
  display: none;
}

table {
  /* table-layout: fixed; */
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

th,
td {
  text-align: left;
}

.dueDatePassed {
  color: red;
}

.tickets_image {
  max-width: 60%;
  max-height: 60%;
}

thead {
  background-color: var(--Base-Grey-2, #f5f5f5);
  position: sticky;
  top: 0px;
  margin-top: 0em;
}

thead th {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  font: 500 12px Rubik, sans-serif;
  padding: 5px;
  height: 50px;
}

tbody td {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  font: 400 12px Rubik, sans-serif;
  padding: 5px;
  height: 56px;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.0075rem;
}

.priority {
  display: flex;
  align-items: center;
}

.priority_image {
  padding-right: 5px;
  padding-top: 10px;
}

.priority_text {
font-size: 0.8rem;
}

.firstLetter {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  /* background-color: #706d6d; */
  color: white;
}

tbody > tr {
  border-bottom: 0.5px solid var(--Base-Blue-grey-5, #c0c3ce);
}

.tableDescription {
  word-wrap: break-word;
}

.table_header_with_icons {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.table_header_icon {
  margin-left: 10%;
  margin-top: 0;
  margin-right: 0.8em;
}

.count_tickets {
  /* border:1px solid red; */
  margin-top: 3rem;
  color: var(--Blue-Grey-8, var(--Base-Blue-grey-8, #83889e));
  font-family: Rubik, sans-serif;
}

.hide_tickets_table {
  display: none;
}

.no_records_found {
  /* width:100%;
    height:50vh; */
  /* border:1px solid red; */
  text-align: center;
}

.clickupLogo {
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
}

.ticketsTableRow{
cursor: pointer;

}
.ticketsTableRow:hover {
  background-color: #f3ebff; 
}